import { PageProps } from 'gatsby';
import { MemoData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/memo.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageMemosUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, id },
}) => (
  <TemplateEspace collectionName="memos" docId={id} espace={espace} user={user}>
    <CMSView>
      <Form
        displayFeatured
        displayHidden
        docId={id}
        itemPathnamePrefix={`/espaces/${espaceId}/memos/`}
        model={new MemoData({ espaceId, params })}
        name="memo"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageMemosUpdate);
